import { ref } from 'vue';
import useCRMApi from '@/hooks/crm/useCRMApi';
import { companyDefault } from '@/hooks/crm/fieldDefaults';
import useFieldValidation from '@/hooks/useFieldValidation';
import { UpdateFinancingError, UpdateFinancingSuccess } from '@/libs/toasts/feedback/module/crm';
import showToast from '@/libs/toasts';

// TODO: possibly merge with useCompanyDetails
const useCompanyManagement = (company) => {
  const modalState = ref({ add: false, edit: false });

  const fieldValidation = useFieldValidation({
    credit_limit: '',
    used_limit: '',
    payment_days: '',
  });

  const {
    fields,
    errors,
    areFieldsValid,
    validateAll,
    reassignFields,
    emptyErrors,
    setErrors,
  } = fieldValidation;

  const form = ref({});

  const resetState = () => {
    // form.value = { ...fields, ...companyDefault, company_type: [type] };
    reassignFields({ ...companyDefault });
    form.value = { ...fields };
    emptyErrors();
    modalState.value = { add: false, edit: false };
  };

  resetState();

  const updateFinancing = () => {
    validateAll();
    if (!areFieldsValid) return;

    useCRMApi()
      .updateFinancing(company.value?.provider.id, form.value)
      .then(() => {
        // TODO: add routing to company details
        showToast(UpdateFinancingSuccess, { company: company.value?.name });
      })
      .catch(({ response }) => {
        const newErrors = response?.data?.errors;

        if (newErrors) {
          setErrors(response?.data?.errors);
        }

        showToast(UpdateFinancingError);
      });
  };

  return {
    form,
    errors,
    modalState,

    updateFinancing,
    resetState,
    reassignFields,
  };
};

export default useCompanyManagement;
