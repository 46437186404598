<template>
  <div class="company-settings-general">
    <b-container>
      <b-row>
        <b-col cols="9" offset="1">
          <company-form
            v-model="form"
            :feedback="errors"
          />

          <form-action-buttons
            @submit="updateCompany"
          />

        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
} from 'bootstrap-vue';

import FormActionButtons from '@/components/ui/FormActionButtons.vue';
import useCompanyManagement from '@/hooks/crm/useCompanyManagement';
import { inject, watch } from 'vue';
import CompanyForm from '../forms/CompanyForm.vue';

export default {
  setup() {
    const { company, type } = inject('company');

    const {
      form, updateCompany, errors, assignFields,
    } = useCompanyManagement(type, null, company);

    watch(company, () => {
      assignFields({
        ...company.value,
        industry_ids: company.value?.industries ?? [],
        branch_ids: company.value?.regions ?? [],
      });
    });

    return {
      form, company, updateCompany, errors, assignFields,
    };
  },
  components: {
    BContainer,
    BRow,
    BCol,

    FormActionButtons,
    CompanyForm,
  },
};
</script>
