var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "company-settings-services"
  }, [_c('b-container', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "9",
      "offset": "1"
    }
  }, [_c('h3', [_vm._v(" Przypisz / Przypisane: ")]), _c('company-service-form'), _c('form-action-buttons', {
    on: {
      "submit": _vm.assignServices
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }