<template>
  <div class="company-settings-contact">
    <b-container>
      <b-row>
        <b-col>

          <b-tabs
            pills
            vertical
            nav-wrapper-class="nav-vertical"
            class="contact-tabs"
            v-model="editingAddress"
          >
            <b-tab
              v-for="location in addresses"
              :key="location.id"
              :title="location.name"
            >
              <template #title>

                <div class="primary-icon mr-auto" v-if="location.isPrimary">
                  <feather-icon
                    icon="StarIcon"
                    size="21"
                  />
                </div>

                <div class="unsaved" v-if="location.unsaved">
                  <feather-icon
                    icon="AlertCircleIcon"
                    size="21"
                  />
                </div>

                <div class="title ml-auto" v-if="location.name">
                  {{ location.name }}
                </div>

                <div class="title unnamed font-italic ml-auto" v-else>
                  Brak nazwy
                </div>

              </template>

              <company-contact-form
                :location="location"
                @change="() => location.unsaved = true"
                @deletePerson="({ location, person }) => deletePerson(location, person)"
                @setPrimary="(loc) => setPrimary(loc)"
              />
            </b-tab>

            <template #tabs-end>
              <b-nav-item
                role="presentation"
                class="mt-1"
                @click.prevent="createCompanyContact"
              >
                Nowa lokalizacja +
              </b-nav-item>
            </template>
          </b-tabs>

        </b-col>
      </b-row>

      <b-row v-if="addresses.length > 0">
        <b-col cols="12" offset="0" class="d-flex">
          <vs-button class="ml-auto" variant="danger" @click="deleteCurrentAddress">
            Usuń adres kontaktowy
          </vs-button>
          <vs-button class="ml-2" variant="primary" @click="saveCurrentAddress">
            Zapisz adres firmy i osoby kontaktowe
          </vs-button>
        </b-col>
      </b-row>

    </b-container>
  </div>
</template>

<script>
import { inject } from 'vue';
import {
  BContainer,
  BRow,
  BCol,
  BTabs,
  BTab,
  BNavItem,
} from 'bootstrap-vue';
import CompanyContactForm from '@/components/views/crm/company/forms/CompanyContactForm.vue';
import useCompanyContactV2 from '@/hooks/crm/useCompanyContactV2';
import VsButton from '@/components/ui/vs-button/VsButton.vue';

export default {
  setup() {
    const companyId = inject('companyId');
    const {
      addresses, fetch, createCompanyContact, editingAddress,
      saveCurrentAddress, deletePerson, setPrimary, deleteCurrentAddress,
    } = useCompanyContactV2(companyId);

    fetch();

    return {
      addresses,
      editingAddress,
      createCompanyContact,
      saveCurrentAddress,
      deletePerson,
      setPrimary,
      deleteCurrentAddress,
    };
  },
  components: {
    BContainer,
    BRow,
    BCol,
    BTabs,
    BTab,
    BNavItem,
    VsButton,

    CompanyContactForm,
  },
};
</script>

<style lang="sass" scoped>
::v-deep .contact-tabs
  .nav-link
    justify-content: end
</style>
