var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "company-contact-person-form"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('crm.company.address.person.field.forename'),
      "invalid-feedback": _vm.errors.forename,
      "state": _vm.errors.forename === null
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('crm.company.address.person.field.forename')
    },
    on: {
      "change": function ($event) {
        return _vm.$emit('change');
      }
    },
    model: {
      value: _vm.temp.forename,
      callback: function ($$v) {
        _vm.$set(_vm.temp, "forename", $$v);
      },
      expression: "temp.forename"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('crm.company.address.person.field.surname'),
      "invalid-feedback": _vm.errors.surname,
      "state": _vm.errors.surname === null
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('crm.company.address.person.field.surname')
    },
    on: {
      "change": function ($event) {
        return _vm.$emit('change');
      }
    },
    model: {
      value: _vm.temp.surname,
      callback: function ($$v) {
        _vm.$set(_vm.temp, "surname", $$v);
      },
      expression: "temp.surname"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('crm.company.address.person.field.position'),
      "invalid-feedback": _vm.errors.position,
      "state": _vm.errors.position === null
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('crm.company.address.person.field.position')
    },
    on: {
      "change": function ($event) {
        return _vm.$emit('change');
      }
    },
    model: {
      value: _vm.temp.position,
      callback: function ($$v) {
        _vm.$set(_vm.temp, "position", $$v);
      },
      expression: "temp.position"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('crm.company.address.person.field.phone'),
      "invalid-feedback": _vm.errors.phone_number,
      "state": _vm.errors.phone_number === null
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('crm.company.address.person.field.phone')
    },
    on: {
      "change": function ($event) {
        return _vm.$emit('change');
      }
    },
    model: {
      value: _vm.temp.phoneNumber,
      callback: function ($$v) {
        _vm.$set(_vm.temp, "phoneNumber", $$v);
      },
      expression: "temp.phoneNumber"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('crm.company.address.person.field.email'),
      "invalid-feedback": _vm.errors.email,
      "state": _vm.errors.email === null
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('crm.company.address.person.field.email')
    },
    on: {
      "change": function ($event) {
        return _vm.$emit('change');
      }
    },
    model: {
      value: _vm.temp.email,
      callback: function ($$v) {
        _vm.$set(_vm.temp, "email", $$v);
      },
      expression: "temp.email"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('crm.company.address.person.field.gdpr'),
      "invalid-feedback": _vm.errors.rodo,
      "state": _vm.errors.rodo === null
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.$emit('change');
      }
    },
    model: {
      value: _vm.temp.rodo,
      callback: function ($$v) {
        _vm.$set(_vm.temp, "rodo", $$v);
      },
      expression: "temp.rodo"
    }
  })], 1), _c('b-button', {
    staticClass: "delete-button",
    attrs: {
      "variant": "light"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return function () {
          return _vm.$emit('delete');
        }.apply(null, arguments);
      }
    }
  }, [_vm._v(" Usuń osobę kontaktową ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }