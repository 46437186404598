var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "company-settings-finance"
  }, [_c('b-container', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "9",
      "offset": "1"
    }
  }, [_c('company-financing-form', {
    attrs: {
      "feedback": _vm.errors
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }), _c('form-action-buttons', {
    on: {
      "submit": _vm.updateFinancing
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }