import i18n from '@/libs/i18n';
import { deleteModal } from '@/libs/modals';
import { ref } from 'vue';
import CompanyContact from './contact/CompanyContact';
import CompanyAddressPerson from './contact/CompanyContactAddress';
import useCRMApi from './useCRMApi';

/**
 * hook v2, dont delete the old one yet
 */

export default function useCompanyContactEnhanced(companyId) {
  const addresses = ref([]);
  const editingAddress = ref(null);

  const setPrimary = (location) => {
    const currentPrimary = addresses.value.find((address) => address.isPrimary);

    location.setPrimary()
      .then(() => { currentPrimary.isPrimary = false; })
      .catch(() => { currentPrimary.isPrimary = true; });
  };

  const createCompanyContact = () => {
    const contact = new CompanyContact({ companyId });
    addresses.value.push(contact);

    setTimeout(() => {
      editingAddress.value = addresses.value.length - 1;
    }, 0);
  };

  const saveCurrentAddress = async () => {
    const tab = editingAddress.value;
    const address = addresses.value[editingAddress.value];

    if (address.id !== null) {
      await address.update();
    } else {
      await address.store(addresses.value.length === 1);
    }

    setTimeout(() => {
      editingAddress.value = tab;
    }, 0);
  };

  const deleteCurrentAddress = async () => {
    const address = addresses.value[editingAddress.value];

    if (!address.id) {
      addresses.value = addresses.value.filter((item) => item !== address);
      return true;
    }

    const confirmation = await deleteModal({
      title: i18n.t('crm.address.modal.deleteAddress.title'),
      text: i18n.t('crm.address.modal.deleteAddress.text', { name: address.name }),
    });

    if (!confirmation) return false;

    address.delete()
      .then(() => {
        addresses.value = addresses.value.filter((item) => item.id !== address.id);
      });

    return true;
  };

  const fetch = () => {
    useCRMApi()
      .fetchCompanyContact(companyId)
      .then(({ data }) => {
        addresses.value = data?.data?.map((contact) => {
          const {
            // eslint-disable-next-line camelcase
            phone_number, is_primary, postal_code, street_number,
          } = contact;

          return new CompanyContact({
            ...contact,
            phoneNumber: phone_number,
            postalCode: postal_code,
            streetNumber: street_number,
            companyId,
            // eslint-disable-next-line camelcase
            isPrimary: !!is_primary,
            contacts: contact.contacts
              // FIXME: looks messy, but works fine
              .map((person) => new CompanyAddressPerson({
                id: person.id,
                forename: person.forename,
                surname: person.surname,
                position: person.position,
                phoneNumber: person.phone_number,
                email: person.email,
                rodo: !!person.rodo,
              })),
          });
        });
      })
      .catch(() => {});
  };

  const deletePerson = (location, person) => location.deletePerson(person);

  return {
    addresses,
    editingAddress,

    createCompanyContact,
    saveCurrentAddress,
    deletePerson,
    setPrimary,
    fetch,
    deleteCurrentAddress,
  };
}
