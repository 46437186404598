var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "company-contact-form"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
      }
    }
  }, [!_vm.localValue.isPrimary && _vm.localValue.id ? _c('b-button', {
    staticClass: "pl-0",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('setPrimary', _vm.localValue);
      }
    }
  }, [_vm._v(" Kliknij tutaj, aby ustawić adres jako siedzibę główną firmy ")]) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('crm.company.address.field.branch'),
      "invalid-feedback": _vm.errors.name,
      "state": _vm.errors.name === null
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('crm.company.address.field.branch')
    },
    on: {
      "change": function ($event) {
        return _vm.$emit('change');
      }
    },
    model: {
      value: _vm.localValue.name,
      callback: function ($$v) {
        _vm.$set(_vm.localValue, "name", $$v);
      },
      expression: "localValue.name"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('crm.company.address.field.street'),
      "invalid-feedback": _vm.errors.street,
      "state": _vm.errors.street === null
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('crm.company.address.field.street')
    },
    on: {
      "change": function ($event) {
        return _vm.$emit('change');
      }
    },
    model: {
      value: _vm.localValue.street,
      callback: function ($$v) {
        _vm.$set(_vm.localValue, "street", $$v);
      },
      expression: "localValue.street"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('crm.company.address.field.streetNumber'),
      "invalid-feedback": _vm.errors.street_number,
      "state": _vm.errors.street_number === null
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('crm.company.address.field.streetNumber')
    },
    on: {
      "change": function ($event) {
        return _vm.$emit('change');
      }
    },
    model: {
      value: _vm.localValue.streetNumber,
      callback: function ($$v) {
        _vm.$set(_vm.localValue, "streetNumber", $$v);
      },
      expression: "localValue.streetNumber"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('crm.company.address.field.postalCode'),
      "invalid-feedback": _vm.errors.postal_code,
      "state": _vm.errors.postal_code === null
    }
  }, [_c('b-input-group', [_c('cleave', {
    staticClass: "form-control",
    attrs: {
      "raw": "",
      "id": "contactPostalCode",
      "options": {
        blocks: [2, 3],
        delimiter: '-'
      },
      "placeholder": "00-000"
    },
    on: {
      "change": function ($event) {
        return _vm.$emit('change');
      }
    },
    model: {
      value: _vm.localValue.postalCode,
      callback: function ($$v) {
        _vm.$set(_vm.localValue, "postalCode", $$v);
      },
      expression: "localValue.postalCode"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('crm.company.address.field.city'),
      "invalid-feedback": _vm.errors.city,
      "state": _vm.errors.city === null
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('crm.company.address.field.city')
    },
    on: {
      "change": function ($event) {
        return _vm.$emit('change');
      }
    },
    model: {
      value: _vm.localValue.city,
      callback: function ($$v) {
        _vm.$set(_vm.localValue, "city", $$v);
      },
      expression: "localValue.city"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('crm.company.address.field.phone'),
      "invalid-feedback": _vm.errors.phone_number,
      "state": _vm.errors.phone_number === null
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('crm.company.address.field.phone')
    },
    on: {
      "change": function ($event) {
        return _vm.$emit('change');
      }
    },
    model: {
      value: _vm.localValue.phoneNumber,
      callback: function ($$v) {
        _vm.$set(_vm.localValue, "phoneNumber", $$v);
      },
      expression: "localValue.phoneNumber"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('crm.company.address.field.email'),
      "invalid-feedback": _vm.errors.email,
      "state": _vm.errors.email === null
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('crm.company.address.field.email')
    },
    on: {
      "change": function ($event) {
        return _vm.$emit('change');
      }
    },
    model: {
      value: _vm.localValue.email,
      callback: function ($$v) {
        _vm.$set(_vm.localValue, "email", $$v);
      },
      expression: "localValue.email"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "section-header"
  }, [_c('h5', {
    staticClass: "section-header-text"
  }, [_vm._v(" Osoby kontaktowe ")]), _c('b-button', {
    staticClass: "section-header-button",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function () {
        return _vm.location.createCompanyContactPerson();
      }
    }
  }, [_vm._v(" + Dodaj osobę ")])], 1), _c('hr'), _c('app-collapse', {
    attrs: {
      "accordion": ""
    }
  }, _vm._l(_vm.location.contacts, function (person) {
    return _c('app-collapse-item', {
      key: person.id,
      attrs: {
        "title": 'new person'
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function () {
          return [_c('span', {
            staticClass: "lead collapse-title"
          }, [_c('div', {
            staticClass: "header"
          }, [person.forename || person.surname ? _c('span', {
            staticClass: "name"
          }, [_vm._v(" " + _vm._s(person.forename) + " " + _vm._s(person.surname) + " ")]) : _c('div', {
            staticClass: "title unnamed font-italic"
          }, [_vm._v(" Brak nazwy ")])]), person.unsaved ? _c('div', {
            staticClass: "unsaved"
          }, [_c('feather-icon', {
            attrs: {
              "icon": "ActivityIcon"
            }
          }), _c('div', {
            staticClass: "text"
          }, [_vm._v(" Niezapisane zmiany ")])], 1) : _vm._e()])];
        },
        proxy: true
      }], null, true)
    }, [_c('company-contact-person-form', {
      attrs: {
        "person": person
      },
      on: {
        "delete": function () {
          return _vm.$emit('deletePerson', {
            location: _vm.location,
            person
          });
        },
        "change": function ($event) {
          person.unsaved = true;
        }
      }
    })], 1);
  }), 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }