<template>
  <div class="financing-form">
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="$t('crm.company.field.financing.dateOfPayment')"
            :invalid-feedback="errors.payment_days"
            :state="errors.payment_days === null"
            label-cols-md="3"
          >
            <b-input-group>
              <b-form-input
                :placeholder="$t('crm.company.field.financing.dateOfPayment')"
                v-model="financing.payment_days"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="$t('crm.company.field.financing.creditLimit')"
            :invalid-feedback="errors.credit_limit"
            :state="errors.credit_limit === null"
            label-cols-md="3"
          >
            <b-input-group>
              <b-form-input
                :placeholder="$t('crm.company.field.financing.creditLimit')"
                v-model="financing.credit_limit"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="$t('crm.company.field.financing.usedCreditLimit')"
            :invalid-feedback="errors.used_limit"
            :state="errors.used_limit === null"
            label-cols-md="3"
          >
            <b-input-group>
              <b-form-input
                :placeholder="$t('crm.company.field.financing.usedCreditLimit')"
                v-model="financing.used_limit"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
} from 'bootstrap-vue';
import { computed, watch } from 'vue';
import i18n from '@/libs/i18n';
import formatDate from '@/libs/date-formatter';

export default {
  name: 'CompanyFinancingForm',
  props: {
    value: {
      type: Object,
    },
    feedback: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const financing = computed({
      get: () => props.value,
    });

    const paymentDate = computed({
      get() {
        return formatDate.standard(financing.value.payment_days);
      },

      set(newDate) {
        financing.value.payment_days = newDate;
      },
    });

    const errors = computed(() => {
      const ble = {};

      Object.keys(props?.feedback)
        .forEach((key) => {
          ble[key] = props.feedback?.[key]?.[0];
        });

      return ble;
    });

    watch(financing.value, (val) => emit('input', val));

    return { errors, financing, paymentDate };
  },
  components: {
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
  },
  computed: {
    calendarTranslations: () => i18n.t('global.calendar'),
  },
};
</script>

<style lang="sass" scoped>
.financing-form::v-deep
  .vs-wysiwyg
    margin-top: 15px
</style>
