import RemoteResourceManager from '@/libs/RemoteResourceManager';
import showToast from '@/libs/toasts';
import {
  CreateContactError,
  CreateContactSuccess,
  DeleteContactError,
  DeleteContactSuccess,
  SetPrimaryContactError,
  SetPrimaryContactSuccess,
  UpdateContactError,
  UpdateContactPersonError,
  UpdateContactPersonSuccess,
  UpdateContactSuccess,
} from '@/libs/toasts/feedback/module/crm';
import useCRMApi from '../useCRMApi';
import CompanyAddressPerson from './CompanyContactAddress';

export default class CompanyAddress {
  constructor({
    id = null,
    name = null,
    street = null,
    streetNumber = null,
    postalCode = null,
    city = null,
    phoneNumber = null,
    email = null,
    companyId = null,
    isPrimary = null,
    contacts = [],
  } = {}) {
    this.id = id;
    this.name = name;
    this.street = street;
    this.streetNumber = streetNumber;
    this.postalCode = postalCode;
    this.city = city;
    this.phoneNumber = phoneNumber;
    this.email = email;
    this.companyId = companyId;
    this.isPrimary = isPrimary;
    this.unsaved = false;

    this.errors = {};

    // CompanyAddressPerson[]
    this.contacts = contacts ?? [];
  }

  async setPrimary() {
    const request = useCRMApi()
      .setPrimaryCompanyContact(this.id);

    const self = this;

    await request
      .then(() => {
        self.isPrimary = true;
        showToast(SetPrimaryContactSuccess);
      })
      .catch(() => showToast(SetPrimaryContactError));

    return request;
  }

  assignContactErrors(errors) {
    this.contacts.forEach((_, idx) => {
      this.contacts[idx].errors = {};
    });

    Object.keys(errors)
      .map((error) => [error.split('.').slice(1, 3)]
        .forEach((err) => {
          // get rid of 'address.0.<field>' and replace with '<field>'
          const friendlyErrors = errors[error].map((fr) => fr.replace(error, err[1]));

          this.contacts[err[0]].errors[err[1]] = friendlyErrors;
        }));

    // assuming no errors = successful save
    // so switching back to saved state
    this.contacts.forEach((contact, idx) => {
      if (contact.unsaved && Object.keys(contact.errors).length === 0) {
        this.contacts[idx].unsaved = false;
      }
    });
  }

  saveContacts() {
    if (!this.contacts.some((contact) => contact.unsaved)) return;

    const affectedContacts = this.contacts
      .filter((contact) => contact.unsaved === true);

    const address = affectedContacts.map((contact) => contact.getDataObject());

    useCRMApi()
      .createManyCompanyContactPersons({
        address,
        company_address_id: this.id,
      })
      .then(({ data }) => {
        this.contacts.forEach((_, idx) => {
          this.contacts[idx].unsaved = false;
        });

        affectedContacts.forEach((_, idx) => {
          affectedContacts[idx].id = data.data[idx].id;
        });

        RemoteResourceManager.getInstance().notify('crm.provider');
        showToast(UpdateContactPersonSuccess);
      })
      .catch(({ response }) => {
        const errors = response?.data?.errors;
        this.assignContactErrors(errors);
        showToast(UpdateContactPersonError);
      });
  }

  update() {
    const request = useCRMApi()
      .updateCompanyContact(this.getDataObject());

    request
      .then(() => {
        this.unsaved = false;
        RemoteResourceManager.getInstance().notify('crm.provider');
        showToast(UpdateContactSuccess);
        this.saveContacts();
      })
      .catch(({ response }) => {
        this.errors = response?.data?.errors;
        showToast(UpdateContactError);
      });

    return request;
  }

  store(markAsPrimary = false) {
    const request = useCRMApi()
      .createCompanyContact(this.getDataObject());

    request
      .then(({ data }) => {
        this.id = data.data.id;
        this.unsaved = false;

        if (markAsPrimary) {
          this.setPrimary(true);
        }

        RemoteResourceManager.getInstance().notify('crm.provider');
        showToast(CreateContactSuccess);
        this.saveContacts();
      })
      .catch(({ response }) => {
        this.errors = response?.data?.errors;
        showToast(CreateContactError);
      });

    return request;
  }

  createCompanyContactPerson() {
    this.contacts.push(new CompanyAddressPerson());
  }

  getErrors() {
    if (!this.errors) return {};
    const errors = {};

    Object
      .keys(this.errors)
      .forEach((error) => {
        [errors[error]] = this.errors[error];
      });

    return errors;
  }

  async deletePerson(person) {
    const removeFromList = () => {
      this.contacts = this.contacts.filter((contact) => contact.id !== person.id);
    };
    if (!person.id) return removeFromList();

    const deleted = await person.delete();

    if (deleted) {
      removeFromList();
    }

    return deleted;
  }

  async delete() {
    const request = useCRMApi()
      .deleteCompanyContact(this.id);

    await request
      .then(() => {
        RemoteResourceManager.getInstance().notify('crm.provider');
        showToast(DeleteContactSuccess);
      })
      .catch(() => showToast(DeleteContactError));

    return request;
  }

  getDataObject() {
    const {
      id, name, street, streetNumber, postalCode, city, phoneNumber, email, companyId,
    } = this;

    const data = {
      id,
      name,
      street,
      street_number: streetNumber,
      postal_code: postalCode,
      city,
      phone_number: phoneNumber,
      email,
      company_id: companyId,
    };

    Object.keys(data)
      .forEach((key) => (data[key] === null ? delete data[key] : null));

    return data;
  }
}
