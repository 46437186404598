<template>
  <b-form>
    <crm-service-picker
      multiple
      v-model="assigned"
      :closeOnSelect="false"
    />
  </b-form>
</template>

<script>
import {
  BForm,
} from 'bootstrap-vue';
import { inject } from 'vue';
import CrmServicePicker from '@/components/module/crm/CrmServicePicker.vue';

export default {
  name: 'CompanyServiceForm',
  setup() {
    const { assigned } = inject('serviceInstance');

    return { assigned };
  },
  components: {
    BForm,
    CrmServicePicker,
  },
};
</script>

<style scoped>

</style>
