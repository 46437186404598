import i18n from '@/libs/i18n';
import showToast from '@/libs/toasts';
import { DeleteContactPersonError, DeleteContactPersonSuccess } from '@/libs/toasts/feedback/module/crm';
import { deleteModal } from '@/libs/modals';
import useCRMApi from '../useCRMApi';

export default class CompanyAddressPerson {
  constructor({
    id = null,
    forename = null,
    surname = null,
    position = null,
    phoneNumber = null,
    email = null,
    rodo = false,
    addressId = null,
  } = {}) {
    this.id = id;
    this.forename = forename;
    this.surname = surname;
    this.position = position;
    this.phoneNumber = phoneNumber;
    this.email = email;
    this.rodo = rodo;
    this.addressId = addressId;

    this.errors = {};
    this.unsaved = false;
  }

  // TODO: move to separate parent class
  getErrors() {
    if (!this.errors) return {};
    const errors = {};

    Object
      .keys(this.errors)
      .forEach((error) => {
        [errors[error]] = this.errors[error];
      });

    return errors;
  }

  async delete() {
    const confirmation = await deleteModal({
      title: i18n.t('crm.address.modal.deletePerson.title'),
      text: i18n.t('crm.address.modal.deletePerson.text', { name: this.forename + this.surname }),
    });

    let success = false;

    if (!confirmation) return false;

    const request = useCRMApi()
      .deleteCompanyContactPerson(this.id);

    await request
      .then(() => {
        showToast(DeleteContactPersonSuccess);
        success = true;
      })
      .catch(() => {
        showToast(DeleteContactPersonError);
        success = false;
      });

    return success;
  }

  getDataObject() {
    const {
      id, forename, surname, position, phoneNumber, email, rodo, addressId,
    } = this;

    return {
      id,
      forename,
      surname,
      position,
      phone_number: phoneNumber,
      rodo,
      email,
      company_address_id: addressId,
    };
  }
}
