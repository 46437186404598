var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form', [_c('crm-service-picker', {
    attrs: {
      "multiple": "",
      "closeOnSelect": false
    },
    model: {
      value: _vm.assigned,
      callback: function ($$v) {
        _vm.assigned = $$v;
      },
      expression: "assigned"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }