<template>
  <div class="company-settings">
    <b-card>
      <b-tabs card>

        <b-tab active>
          <template #title>
            <feather-icon icon="SlidersIcon" />
            Ustawienia firmy
          </template>

          <company-settings-general v-if="company.company" />
        </b-tab>

        <!-- TODO -->
        <b-tab>
          <template #title>
            <feather-icon icon="MailIcon" />

            Dane kontaktowe
          </template>

          <company-settings-contact />
        </b-tab>

        <!-- FIXME these ifs -->
        <b-tab
          v-if="company.company
            && company.company.value.company_type
            && company.company.value.company_type.PROVIDER_TYPE"
        >
          <template #title>
            <feather-icon icon="DollarSignIcon" />

            Finanse
          </template>

          <company-settings-finance />
        </b-tab>

        <b-tab
          v-if="company.company
            && company.company.value.company_type
            && company.company.value.company_type.PROVIDER_TYPE"
        >
          <template #title>
            <feather-icon icon="ActivityIcon" />

            Zakres usług
          </template>

          <company-settings-services />
        </b-tab>

      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
} from 'bootstrap-vue';
import useCompanyDetails from '@/hooks/crm/useCompanyDetails';
import { provide } from 'vue';
import { useRouter } from '@/@core/utils/utils';
import CompanySettingsGeneral from './CompanySettingsGeneral.vue';
import CompanySettingsContact from './CompanySettingsContact.vue';
import CompanySettingsFinance from './CompanySettingsFinance.vue';
import CompanySettingsServices from './CompanySettingsServices.vue';

export default {
  setup() {
    const { route } = useRouter();
    const companyId = route.value.params.id;

    provide('companyId', companyId);
    const company = useCompanyDetails(companyId);

    provide('company', company);

    return { company };
  },
  components: {
    BCard,
    BTab,
    BTabs,

    CompanySettingsGeneral,
    CompanySettingsContact,
    CompanySettingsFinance,
    CompanySettingsServices,
  },
};
</script>
