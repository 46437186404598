<template>
  <div class="company-contact-person-form">
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12">

          <b-form-group
            :label="$t('crm.company.address.person.field.forename')"
            :invalid-feedback="errors.forename"
            :state="errors.forename === null"
          >
            <b-input-group>
              <b-form-input
                :placeholder="$t('crm.company.address.person.field.forename')"
                v-model="temp.forename"
                @change="$emit('change')"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
            :label="$t('crm.company.address.person.field.surname')"
            :invalid-feedback="errors.surname"
            :state="errors.surname === null"
          >
            <b-input-group>
              <b-form-input
                :placeholder="$t('crm.company.address.person.field.surname')"
                v-model="temp.surname"
                @change="$emit('change')"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
            :label="$t('crm.company.address.person.field.position')"
            :invalid-feedback="errors.position"
            :state="errors.position === null"
          >
            <b-input-group>
              <b-form-input
                :placeholder="$t('crm.company.address.person.field.position')"
                v-model="temp.position"
                @change="$emit('change')"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
            :label="$t('crm.company.address.person.field.phone')"
            :invalid-feedback="errors.phone_number"
            :state="errors.phone_number === null"
          >
            <b-input-group>
              <b-form-input
                :placeholder="$t('crm.company.address.person.field.phone')"
                v-model="temp.phoneNumber"
                @change="$emit('change')"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
            :label="$t('crm.company.address.person.field.email')"
            :invalid-feedback="errors.email"
            :state="errors.email === null"
          >
            <b-input-group>
              <b-form-input
                :placeholder="$t('crm.company.address.person.field.email')"
                v-model="temp.email"
                @change="$emit('change')"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
            :label="$t('crm.company.address.person.field.gdpr')"
            :invalid-feedback="errors.rodo"
            :state="errors.rodo === null"
          >
            <b-form-checkbox
              switch
              @change="$emit('change')"
              v-model="temp.rodo">
            </b-form-checkbox>
          </b-form-group>

          <b-button
            class="delete-button"
            variant="light"
            @click.prevent="() => $emit('delete')"
          >
            Usuń osobę kontaktową
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue';
import { computed } from 'vue';

export default {
  props: {
    person: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const temp = computed({
      get() {
        return props.person;
      },
    });

    const errors = computed(() => props.person.getErrors());

    return { temp, errors };
  },
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormCheckbox,
  },
};
</script>
