var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "company-settings-contact"
  }, [_c('b-container', [_c('b-row', [_c('b-col', [_c('b-tabs', {
    staticClass: "contact-tabs",
    attrs: {
      "pills": "",
      "vertical": "",
      "nav-wrapper-class": "nav-vertical"
    },
    scopedSlots: _vm._u([{
      key: "tabs-end",
      fn: function () {
        return [_c('b-nav-item', {
          staticClass: "mt-1",
          attrs: {
            "role": "presentation"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.createCompanyContact.apply(null, arguments);
            }
          }
        }, [_vm._v(" Nowa lokalizacja + ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.editingAddress,
      callback: function ($$v) {
        _vm.editingAddress = $$v;
      },
      expression: "editingAddress"
    }
  }, _vm._l(_vm.addresses, function (location) {
    return _c('b-tab', {
      key: location.id,
      attrs: {
        "title": location.name
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function () {
          return [location.isPrimary ? _c('div', {
            staticClass: "primary-icon mr-auto"
          }, [_c('feather-icon', {
            attrs: {
              "icon": "StarIcon",
              "size": "21"
            }
          })], 1) : _vm._e(), location.unsaved ? _c('div', {
            staticClass: "unsaved"
          }, [_c('feather-icon', {
            attrs: {
              "icon": "AlertCircleIcon",
              "size": "21"
            }
          })], 1) : _vm._e(), location.name ? _c('div', {
            staticClass: "title ml-auto"
          }, [_vm._v(" " + _vm._s(location.name) + " ")]) : _c('div', {
            staticClass: "title unnamed font-italic ml-auto"
          }, [_vm._v(" Brak nazwy ")])];
        },
        proxy: true
      }], null, true)
    }, [_c('company-contact-form', {
      attrs: {
        "location": location
      },
      on: {
        "change": function () {
          return location.unsaved = true;
        },
        "deletePerson": function (_ref) {
          var location = _ref.location,
            person = _ref.person;
          return _vm.deletePerson(location, person);
        },
        "setPrimary": function (loc) {
          return _vm.setPrimary(loc);
        }
      }
    })], 1);
  }), 1)], 1)], 1), _vm.addresses.length > 0 ? _c('b-row', [_c('b-col', {
    staticClass: "d-flex",
    attrs: {
      "cols": "12",
      "offset": "0"
    }
  }, [_c('vs-button', {
    staticClass: "ml-auto",
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": _vm.deleteCurrentAddress
    }
  }, [_vm._v(" Usuń adres kontaktowy ")]), _c('vs-button', {
    staticClass: "ml-2",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.saveCurrentAddress
    }
  }, [_vm._v(" Zapisz adres firmy i osoby kontaktowe ")])], 1)], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }