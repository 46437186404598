<template>
  <div class="company-settings-finance">
    <b-container>
      <b-row>
        <b-col cols="9" offset="1">

          <company-financing-form
            v-model="form"
            :feedback="errors"
          />

          <form-action-buttons
            @submit="updateFinancing"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import CompanyFinancingForm from '@/components/views/crm/company/forms/CompanyFinancingForm.vue';
import FormActionButtons from '@/components/ui/FormActionButtons.vue';
import {
  BCol,
  BContainer,
  BRow,
} from 'bootstrap-vue';
import useCompanyFinanceManagement from '@/hooks/crm/useCompanyFinanceManagement';
import { inject } from 'vue';

export default {
  name: 'CompanySettingsFinance',
  setup() {
    const { company } = inject('company');

    const {
      form, reassignFields, updateFinancing, errors,
    } = useCompanyFinanceManagement(company);

    reassignFields(company.value?.provider);

    return { errors, form, updateFinancing };
  },
  components: {
    CompanyFinancingForm,
    FormActionButtons,
    BRow,
    BCol,
    BContainer,
  },
};
</script>
