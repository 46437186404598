<template>
  <div class="company-contact-form">
    <b-form @submit.prevent>

      <b-button
        v-if="!localValue.isPrimary && localValue.id"
        variant="link"
        class="pl-0"
        @click="$emit('setPrimary', localValue)"
      >
        Kliknij tutaj, aby ustawić adres jako siedzibę główną firmy
      </b-button>

      <b-row>
        <b-col cols="6">
          <b-form-group
            :label="$t('crm.company.address.field.branch')"
            :invalid-feedback="errors.name"
            :state="errors.name === null"
          >
            <b-input-group>
              <b-form-input
                :placeholder="$t('crm.company.address.field.branch')"
                v-model="localValue.name"
                @change="$emit('change')"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
            :label="$t('crm.company.address.field.street')"
            :invalid-feedback="errors.street"
            :state="errors.street === null"
          >
            <b-input-group>
              <b-form-input
                :placeholder="$t('crm.company.address.field.street')"
                v-model="localValue.street"
                @change="$emit('change')"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
            :label="$t('crm.company.address.field.streetNumber')"
            :invalid-feedback="errors.street_number"
            :state="errors.street_number === null"
          >
            <b-input-group>
              <b-form-input
                :placeholder="$t('crm.company.address.field.streetNumber')"
                v-model="localValue.streetNumber"
                @change="$emit('change')"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
            :label="$t('crm.company.address.field.postalCode')"
            :invalid-feedback="errors.postal_code"
            :state="errors.postal_code === null"
          >
            <b-input-group>
              <cleave raw
                id="contactPostalCode"
                v-model="localValue.postalCode"
                class="form-control"
                :options="{ blocks: [2, 3], delimiter: '-' }"
                placeholder="00-000"
                @change="$emit('change')"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
            :label="$t('crm.company.address.field.city')"
            :invalid-feedback="errors.city"
            :state="errors.city === null"
          >
            <b-input-group>
              <b-form-input
                :placeholder="$t('crm.company.address.field.city')"
                v-model="localValue.city"
                @change="$emit('change')"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
            :label="$t('crm.company.address.field.phone')"
            :invalid-feedback="errors.phone_number"
            :state="errors.phone_number === null"
          >
            <b-input-group>
              <b-form-input
                :placeholder="$t('crm.company.address.field.phone')"
                v-model="localValue.phoneNumber"
                @change="$emit('change')"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
            :label="$t('crm.company.address.field.email')"
            :invalid-feedback="errors.email"
            :state="errors.email === null"
          >
            <b-input-group>
              <b-form-input
                :placeholder="$t('crm.company.address.field.email')"
                v-model="localValue.email"
                @change="$emit('change')"
              />
            </b-input-group>
          </b-form-group>

        </b-col>

        <b-col cols="6">

          <div class="section-header">
            <h5 class="section-header-text">
              Osoby kontaktowe
            </h5>

            <b-button
              class="section-header-button"
              @click="() => location.createCompanyContactPerson()"
              variant="link"
            >
              + Dodaj osobę
            </b-button>
          </div>

          <hr>

          <app-collapse accordion>
            <app-collapse-item
              v-for="person in location.contacts"
              :key="person.id"
              :title="'new person'"
              >
              <template #header>
                <span class="lead collapse-title">
                  <div class="header">
                    <span class="name" v-if="person.forename || person.surname">
                      {{ person.forename }} {{ person.surname }}
                    </span>
                    <div class="title unnamed font-italic" v-else>
                      Brak nazwy
                    </div>
                  </div>

                  <div class="unsaved" v-if="person.unsaved">
                    <feather-icon icon="ActivityIcon" />
                    <div class="text">
                      Niezapisane zmiany
                    </div>
                  </div>
                </span>
              </template>

              <company-contact-person-form
                :person="person"
                @delete="() => $emit('deletePerson', { location, person })"
                @change="person.unsaved = true"
              />

            </app-collapse-item>
          </app-collapse>
        </b-col>

      </b-row>
    </b-form>

  </div>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import { computed } from 'vue';
import Cleave from 'vue-cleave-component';
import CompanyContactPersonForm from './CompanyContactPersonForm.vue';

export default {
  props: {
    location: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const localValue = computed(() => props.location);
    const errors = computed(() => props.location.getErrors());

    return {
      localValue,
      errors,
    };
  },
  components: {
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,

    AppCollapse,
    AppCollapseItem,

    CompanyContactPersonForm,
    Cleave,
  },
};
</script>

<style lang="sass" scoped>
.section-header
  display: flex
  align-items: center

  &-text
    margin-top: 7px

  &-button
    margin-left: auto
    padding-right: 0

.collapse-title
  display: flex
  align-items: center

  .unsaved
    color: #fc973f
    margin-left: 10px
    display: flex
    align-items: center

    .text
      margin-left: 10px
</style>
