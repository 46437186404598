<template>
  <div class="company-settings-services">
    <b-container>
      <b-row>
        <b-col cols="9" offset="1">
          <h3>
            Przypisz / Przypisane:
          </h3>

          <company-service-form />

          <form-action-buttons
            @submit="assignServices"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import FormActionButtons from '@/components/ui/FormActionButtons.vue';
import CompanyServiceForm from '@/components/views/crm/company/forms/CompanyServiceForm.vue';
import {
  BCol,
  BContainer,
  BRow,
} from 'bootstrap-vue';
import useServices from '@/hooks/settings/crm/useServices';
import { provide, inject } from 'vue';

export default {
  name: 'CompanySettingsServices',
  setup() {
    const { company } = inject('company');

    const serviceInstance = useServices(company.value?.provider?.services);
    provide('serviceInstance', serviceInstance);

    serviceInstance.assigned.value = company.value?.provider?.services
      ?.map((service) => service.id) ?? [];

    return { assignServices: () => serviceInstance.assignServices(company.value?.provider?.id) };
  },
  data() {
    return {
      services: [
        {
          id: 1,
          name: 'Cement',
        },
        {
          id: 2,
          name: 'Błoto',
        },
      ],
    };
  },
  components: {
    CompanyServiceForm,
    FormActionButtons,
    BRow,
    BCol,
    BContainer,
  },
};
</script>
