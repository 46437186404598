var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "financing-form"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('crm.company.field.financing.dateOfPayment'),
      "invalid-feedback": _vm.errors.payment_days,
      "state": _vm.errors.payment_days === null,
      "label-cols-md": "3"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('crm.company.field.financing.dateOfPayment')
    },
    model: {
      value: _vm.financing.payment_days,
      callback: function ($$v) {
        _vm.$set(_vm.financing, "payment_days", $$v);
      },
      expression: "financing.payment_days"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('crm.company.field.financing.creditLimit'),
      "invalid-feedback": _vm.errors.credit_limit,
      "state": _vm.errors.credit_limit === null,
      "label-cols-md": "3"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('crm.company.field.financing.creditLimit')
    },
    model: {
      value: _vm.financing.credit_limit,
      callback: function ($$v) {
        _vm.$set(_vm.financing, "credit_limit", $$v);
      },
      expression: "financing.credit_limit"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('crm.company.field.financing.usedCreditLimit'),
      "invalid-feedback": _vm.errors.used_limit,
      "state": _vm.errors.used_limit === null,
      "label-cols-md": "3"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('crm.company.field.financing.usedCreditLimit')
    },
    model: {
      value: _vm.financing.used_limit,
      callback: function ($$v) {
        _vm.$set(_vm.financing, "used_limit", $$v);
      },
      expression: "financing.used_limit"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }