var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "company-settings"
  }, [_c('b-card', [_c('b-tabs', {
    attrs: {
      "card": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          attrs: {
            "icon": "SlidersIcon"
          }
        }), _vm._v(" Ustawienia firmy ")];
      },
      proxy: true
    }])
  }, [_vm.company.company ? _c('company-settings-general') : _vm._e()], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          attrs: {
            "icon": "MailIcon"
          }
        }), _vm._v(" Dane kontaktowe ")];
      },
      proxy: true
    }])
  }, [_c('company-settings-contact')], 1), _vm.company.company && _vm.company.company.value.company_type && _vm.company.company.value.company_type.PROVIDER_TYPE ? _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          attrs: {
            "icon": "DollarSignIcon"
          }
        }), _vm._v(" Finanse ")];
      },
      proxy: true
    }], null, false, 3792061500)
  }, [_c('company-settings-finance')], 1) : _vm._e(), _vm.company.company && _vm.company.company.value.company_type && _vm.company.company.value.company_type.PROVIDER_TYPE ? _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ActivityIcon"
          }
        }), _vm._v(" Zakres usług ")];
      },
      proxy: true
    }], null, false, 2174355520)
  }, [_c('company-settings-services')], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }